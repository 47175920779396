export const MenuList = [
  //Dashboard
  {
    title: "Dashboard",
    classsChange: "mm-collapse",
    iconStyle: <i className="flaticon-dashboard-1" />,
    to: "dashboard",
  },
  {
    title: "Orders",
    update: "New",
    classsChange: "mm-collapse",
    iconStyle: <i className="flaticon-table" />,
    to: "order-list",
  },
  {
    title: "Services",
    update: "New",
    classsChange: "mm-collapse",
    iconStyle: <i className="flaticon-money" />,
    to: "main-services",
  },
  {
    title: "Properties",
    update: "New",
    classsChange: "mm-collapse",
    iconStyle: <i className="flaticon-381-layer-1" />,
    to: "property-list",
  },
  {
    title: "Guest",
    update: "New",
    classsChange: "mm-collapse",
    iconStyle: <i className = "flaticon-381-user-7" /> ,
    to: "guest-information",
  },
  {
    title: "Setup",
    update: "New",
    classsChange: "mm-collapse",
    iconStyle: <i className = "flaticon-381-settings-5" /> ,
    to: "setup",
  },
  // {
  //   title: "Service Notifications",
  //   update: "New",
  //   classsChange: "mm-collapse",
  //   iconStyle: <i className = "flaticon-381-user-7" /> ,
  //   to: "service-notification",
  //   content: [
  //     // {
  //     //     title:'Add Property',
  //     //     to:'add-property',
  //     // },
  //     // {
  //     //     title:'Property List',
  //     //     to:'property-list',
  //     // },
  //     // {
  //     //     title:'Property Details',
  //     //     to:'property-details',
  //     // },
  //   ],
  // },
];
