import React, { useEffect, useState, useRef, useMemo } from "react";
import {
    useTable,
    useGlobalFilter,
    useFilters,
    usePagination,
} from "react-table";

import { ColumnFilter } from "../FilteringTable/ColumnFilter";
import PmService from "../../../services/PmService";
import { Button, Dropdown, Form, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";

const EGuide = ({ propertyId }) => {
    const COLUMNS = [
		{
		  Header: 'E-Guide Link',
		  Footer: 'E-book Link',
		  accessor: 'pdf_link',
		  Filter: ColumnFilter,
		},
		{
		  Header: 'Open',
		  Footer: 'Open',
		  Cell: ({ row }) => {
			const item = row.original;
			return (
                <Button
                    onClick={() => {
                        if (item.pdf_link) {
                            window.open(item.pdf_link, '_blank');
                        } else {
                            alert('No PDF link available');
                        }
                    }}
                    className="btn btn-primary shadow btn-xs sharp me-1"
                >
                    <i className="fa fa-external-link"></i>
                </Button>
            );
		  },
		},
		{
		  Header: 'Delete',
		  Footer: 'Delete',
		  Cell: ({ row }) => {
			const item = row.original;
			return (
				<Button
					onClick={() => {
                        deleteEGuide(item.id);
					}}
                    style={{
                        backgroundColor: 'red',
                        borderColor: 'red',
                    }}
					className="btn btn-primary shadow btn-xs sharp me-1"
				>
					<i className="fa fa-trash"></i>
				</Button>
			);
		  },
		}
	];

    const [pdfLink, setPdfLink] = useState("");
    const [pdfLinkList, setPdfLinkList] = useState([]);

    const handlePdfSubmit = (e) => {
        e.preventDefault();

        const passData = {
            property_id: propertyId,
            pdf_link: pdfLink,
        };

        PmService.AddEbook(passData)
            .then(res => {
                // console.log(res.success);
                if (res.success) {
                    fetchEbookList();
                }
            })
            .catch(error => {
                console.log(error);
            }).finally(() => {
                setPdfLink("");
            });
    };

    const fetchEbookList = () => {
        PmService.GetPropertyEbooks(propertyId)
            .then(res => {
                setPdfLinkList(res?.data);
            })
            .catch(error => {
                console.log(error);
            });
    };

    const deleteEGuide = (id) => {
        PmService.DeleteEGuide(id)
            .then(res => {
                if (res.success) {
                    fetchEbookList();
                }
            })
            .catch(error => {
                console.log(error);
            });
    };

    useEffect(() => {
        fetchEbookList();
    }, []);

    const columns = useMemo(() => COLUMNS, []);
	const data = useMemo(() => pdfLinkList, [pdfLinkList]);
	const tableInstance = useTable({
		columns,
		data,
		initialState: {
		pageIndex: 0
		}
	}, useFilters, useGlobalFilter, usePagination);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		state,
		page,
		gotoPage,
		pageCount,
		pageOptions,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		setGlobalFilter,
	} = tableInstance;


	const {
		globalFilter,
		pageIndex
	} = state;

    return <>
        <div className="mb-12 col-lg-10 col-md-12 mb-2">
            {/* <label className="form-label">E-Guide Link</label> */}
            <div style={{ display: "flex", alignItems: "center" }}>
                <input
                    type="text"
                    className="form-control"
                    placeholder="Enter E-Guide Link"
                    name="service_title"
                    value={pdfLink}
                    onChange={(e) => setPdfLink(e.target.value)}
                />
                <Button
                    onClick={handlePdfSubmit}
                    variant="success"
                    style={{ marginLeft: "20px" }}
                >
                    Add
                </Button>
            </div>
        </div>

        {
            pdfLinkList.length > 0 && (
                <div className="table-responsive table-hover fs-14 order-list-table">
                    <table
                        {...getTableProps()}
                        className="table filtering-table table-responsive-lg"
                    >
                        <thead>
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th {...column.getHeaderProps()}>
                                            {column.render("Header")}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()} className="">
                            {page.map((row) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell) => {
                                            return (
                                                <td {...cell.getCellProps()}>
                                                    {" "}
                                                    {cell.render("Cell")}{" "}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    <div className="d-flex justify-content-between">
                        <span>
                            Page{" "}
                            <strong>
                                {pageIndex + 1} of {pageOptions.length}
                            </strong>
                            {""}
                        </span>
                        <span className="table-index">
                            Go to page :{" "}
                            <input
                                type="number"
                                className="ms-2"
                                defaultValue={pageIndex + 1}
                                onChange={(e) => {
                                    const pageNumber = e.target.value
                                        ? Number(e.target.value) - 1
                                        : 0;
                                    gotoPage(pageNumber);
                                }}
                            />
                        </span>
                    </div>
                    <div className="text-center">
                        <div className="filter-pagination  mt-3">
                            <button
                                className=" previous-button"
                                onClick={() => gotoPage(0)}
                                disabled={!canPreviousPage}
                            >
                                {"<<"}
                            </button>

                            <button
                                className="previous-button"
                                onClick={() => previousPage()}
                                disabled={!canPreviousPage}
                            >
                                Previous
                            </button>
                            <button
                                className="next-button"
                                onClick={() => nextPage()}
                                disabled={!canNextPage}
                            >
                                Next
                            </button>
                            <button
                                className=" next-button"
                                onClick={() => gotoPage(pageCount - 1)}
                                disabled={!canNextPage}
                            >
                                {">>"}
                            </button>
                        </div>
                    </div>
                </div>
            )
        }
    </>;
};

export default EGuide;
