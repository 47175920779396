import React, { useEffect, useState, useRef, useMemo } from "react";
import {
	useTable,
	useGlobalFilter,
	useFilters,
	usePagination,
} from "react-table";
import { Link } from "react-router-dom";
import profile from "../../../images/avatar/1.jpg";
import { Button, Dropdown, Form, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import PmService from "../../../services/PmService";
import moment from "moment";
import Loader from "../Loader";
import ModalLoader from "../Loader/ModalLoader";
import Swal from "sweetalert2";
import { ColumnFilter } from "../FilteringTable/ColumnFilter";
import "../FilteringTable/filtering.css";
import { format } from "date-fns";
import { Cell } from "recharts";
import Select from "react-select";
import emojiRegex from 'emoji-regex';

function ServiceNotification() {
	const COLUMNS = [
		{
		  Header: 'ID',
		  Footer: 'ID',
		  accessor: 'id',
		  Filter: ColumnFilter,
		},
		{
		  Header: 'Title',
		  Footer: 'Title',
		  accessor: 'notification_title',
		  Filter: ColumnFilter,
		},
		{
		  Header: 'Description',
		  Footer: 'Description',
		  accessor: 'notification_description',
		  Filter: ColumnFilter,
		},
		{
		  Header: 'Service',
		  Footer: 'Service',
		  accessor: 'service_category.category',
		  Filter: ColumnFilter,
		},
		{
		  Header: 'Status',
		  Footer: 'Status',
		  accessor: 'status',
		  Filter: ColumnFilter,
		  Cell: ({ value }) => (value === 1 ? 'Active' : 'Inactive')
		},
		{
		  Header: 'Fire On',
		  Footer: 'Fire On',
		  accessor: 'available_at',
		  Filter: ColumnFilter,
		},
		{
		  Header: 'Edit',
		  Footer: 'Edit',
		  Cell: ({ row }) => {
			const item = row.original;
			return (
				<Button
					onClick={() => {
						setShowEditModal(true);
						setFields(item);
						setNotificationId(item.id);
					}}
					className="btn btn-primary shadow btn-xs sharp me-1"
				>
					<i className="fa fa-pencil"></i>
				</Button>
			);
		  },
		}
	] 

	const [loader, setLoader] = useState(false);
	const [notificationList, setNotificationList] = useState([]);
	const [addModalOpen, setAddModalOpen] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
	const [formData, setFormData] = useState({
		notification_title: "",
		notification_desc: "",
		status: {
			value: "1",
			label: "Active",
		}
	});
	const [serviceCategoryId, setServiceCategoryId] = useState({
        value: "",
        label: "",
    });

	const errorsObj = {
        notification_title: "",
		notification_desc: "",
        available_at: "",
		image: ""
    };

	const [errors, setErrors] = useState(errorsObj);
	const [availableAt, setAvailableAt] = useState([]);
	const [serviceCategoryList, setServiceCategoryList] = useState([]);
	const [modifiedTitle, setModifiedTitle] = useState("");
	const [modifiedDesc, setModifiedDesc] = useState("");
	const [notificationId, setNotificationId] = useState(null);
	const [image, setImage] = useState(null);

	const Status = [
        {
            value: "1",
            label: "Active",
        },
        {
            value: "0",
            label: "Inactive",
        },
    ];

	const regex = emojiRegex();

	const convertEmojiToUnicode = (str) => {
		return str.replace(regex, (emoji) => {
			const codePoints = [...emoji].map(char => `U+${char.codePointAt(0).toString(16).toUpperCase()}`).join('');
			return codePoints;
		});
	};

	const handleChange = (e) => {
        const { name, value } = e.target;

		setFormData((prevData) => ({
			...prevData,
			[name]: value
		}));

		if (name == 'notification_title') {
			let modified_title = convertEmojiToUnicode(value);
			setModifiedTitle(modified_title);
		}

		if (name == 'notification_desc') {
			let modified_desc = convertEmojiToUnicode(value);
			setModifiedDesc(modified_desc);
		}

        setErrors({
            ...errors,
            [name]: "",
        });
    }

	const handleStatusChange = (e, name) => {
        const { value, label } = e;
        setFormData((prevData) => ({
            ...prevData,
            [name]: { value, label }
        }))
    }

	const handleAvailableAtChange = (e) => {
		const { value, checked } = e.target;
		setErrors({
			...errors,
			available_at: "",
		});
	
		if (checked) {
			setAvailableAt((prev) => [...prev, value]);
		} else {
			setAvailableAt((prev) => prev.filter((item) => item !== value));
		}
	};

	const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setImage(file);
            setErrors({
                ...errors,
                image: "",
            });
        }
    };

	const setFields = (item) => {
        setFormData({
			notification_title: item?.notification_title || "",
			notification_desc: item?.notification_description || "",
			status: {
				value: item?.status,
				label: item?.status == '1' ? 'Active' : 'Inactive',
			}
		});

		if (item.service_category_id) {
			const [service_category] = serviceCategoryList.filter((service_category) => service_category?.value == item?.service_category_id);
			setServiceCategoryId({
				value: item?.service_category_id,
				label: service_category?.label,
			});
		}
        setAvailableAt(JSON.parse(item?.available_at));
    }

	const clearFields = () => {
		setFormData({
			notification_title: "",
			notification_desc: "",
			status: {
				value: "1",
				label: "Active",
			}
		});
		setServiceCategoryId({
			value: "",
			label: "",
		})
		setErrors({});
		setAvailableAt([]);
	};

	const fetchServiceCategoryList = () => {
		setLoader(true);
		PmService.GetServiceCategories()
		  .then((resp) => {
				if (resp.success) {
					const options = resp?.data?.list.map(item => ({
						value: item.id,
						label: item.category
					}));
					setServiceCategoryList(options);
					// console.log("Categories ===", resp.data);
				}
		  })
		  .catch((error) => {
			console.log("error=", error);
		  })
		  .finally(() => {
			setLoader(false);
		  });
	}

	const handleAddServiceNotification = () => {
        let error = true;
        const errorObj = { ...errorsObj };

        if (formData.notification_title.trim() === "") {
            errorObj.notification_title = "Notification Title is required";
            error = false;
        }
        if (formData.notification_desc.trim() === "") {
            errorObj.notification_desc = "Notification Description is required";
            error = false;
        }
        if (availableAt.length <= 0) {
            errorObj.available_at = "Please select atleast one option."
            error = false;
        }
		// if (image === null) {
        //     errorObj.image = "Service Image is required";
        //     error = false;
        // }

        setErrors(errorObj);
        if (!error) {
            return;
        }
        const passData = {
			service_category_id: serviceCategoryId.value,
            notification_title: modifiedTitle,
            notification_desc: modifiedDesc,
            status: formData.status.value,
            available_at: availableAt.toString()
        }

		if (image != null) {
			passData.image = image
		}
		
        setLoader(true);
        PmService.AddNotification(passData)
            .then((resp) => {
                console.log("resp = ", resp);
                if (resp.success) {
                    setAddModalOpen(false);
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: "Notification has been added successfully.",
                    }).finally(function () {
                        window.location.reload();
                    });
                }
            })
            .catch((error) => {
                console.log("error in add service notification = ", error);
                Swal.fire({
                    icon: "error",
                    title: "Oops!",
                    text: "Something went wrong! Please try again later.",
                });
            })
            .finally(function () {
                setLoader(false);
            })
    }

	const handleEditSubService = () => {
        let error = true;
        const errorObj = { ...errorsObj };

        if (formData.notification_title.trim() === "") {
            errorObj.notification_title = "Notification Title is required";
            error = false;
        }
        if (formData.notification_desc.trim() === "") {
            errorObj.notification_desc = "Notification Description is required";
            error = false;
        }
        if (availableAt.length <= 0) {
            errorObj.available_at = "Please select atleast one option."
            error = false;
        }

        setErrors(errorObj);
        if (!error) {
            return;
        }
        const passData = {
			service_category_id: serviceCategoryId.value,
            notification_title: convertEmojiToUnicode(formData.notification_title),
            notification_desc: convertEmojiToUnicode(formData.notification_desc),
            status: formData.status.value,
            available_at: availableAt.toString()
        }

		if (image != null) {
			passData.image = image
		}

        setLoader(true);
        PmService.UpdateNotification(passData, notificationId)
            .then((resp) => {
                console.log("resp = ", resp);
                if (resp.success) {
                    setShowEditModal(false);
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: "Notification has been updated successfully.",
                    }).finally(function () {
                        window.location.reload();
                    });
                }
            })
            .catch((error) => {
                Swal.fire({
                    icon: "error",
                    title: "Oops!",
                    text: "Something went wrong! Please try again later.",
                }).finally(function () {
                    setAddModalOpen(false);
                });
            })
            .finally(function () {
                setLoader(false);
            })
    }

	const fetchServiceNotifications = () => {
		PmService.GetAllServiceNotifications()
			.then((resp) => {
				setNotificationList(resp.data);
			})
			.catch((err) => {
				console.log(err);
			})
	}	  

	useEffect(() => {
		fetchServiceCategoryList();
		fetchServiceNotifications();
	}, []);

	useEffect(() => {
		if (showEditModal && notificationId && serviceCategoryList.length > 0) {
		  const notification = notificationList.find(n => n.id === notificationId);
		  if (notification) {
			setFields(notification);
		  }
		}
	}, [showEditModal, notificationId, serviceCategoryList]);	  

	const columns = useMemo(() => COLUMNS, []);
	const data = useMemo(() => notificationList, [notificationList]);
	const tableInstance = useTable({
		columns,
		data,
		initialState: {
		pageIndex: 0
		}
	}, useFilters, useGlobalFilter, usePagination);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		state,
		page,
		gotoPage,
		pageCount,
		pageOptions,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		setGlobalFilter,
	} = tableInstance;


	const {
		globalFilter,
		pageIndex
	} = state;

	return loader ? (
		<Loader />
	) : (
    <>
		<div className="form-head page-titles d-flex  align-items-center">
			<div className="me-auto  d-lg-block">
			<h4 className="mb-1">Service Notification</h4>
			<ol className="breadcrumb">
				<li className="breadcrumb-item active">
				<Link to="/dashboard">Dashboard</Link>
				</li>
				<li className="breadcrumb-item">
				<Link to="/service-notification">Service Notification List</Link>
				</li>
			</ol>
			</div>
			<Link
				to="#"
				className="btn btn-primary rounded light"
				onClick={() => { clearFields(); setAddModalOpen(true); }}
			>
				Add Notification
			</Link>
		</div>

		<div className="row">
			<div className="col-xl-12">
				<div className="card">
					<div className="card-body">
						<div className="table-responsive table-hover fs-14 order-list-table">
							<table {...getTableProps()} className="table filtering-table table-responsive-lg">
							<thead>
								{headerGroups.map(headerGroup => (
								<tr {...headerGroup.getHeaderGroupProps()}>
									{headerGroup.headers.map(column => (
									<th {...column.getHeaderProps()}>
										{column.render('Header')}
										{column.canFilter ? column.render('Filter') : null}
									</th>
									))}
								</tr>
								))}
							</thead> 
							<tbody {...getTableBodyProps()} className="" >
							
								{page.map((row) => {
								prepareRow(row)
								return(
									<tr {...row.getRowProps()}>
									{row.cells.map((cell) => {
										return <td {...cell.getCellProps()}> {cell.render('Cell')} </td>
									})}
									</tr>
								)
								})}
							</tbody>
							</table>
							<div className="d-flex justify-content-between">
							<span>
								Page{' '}
								<strong>
								{pageIndex + 1} of {pageOptions.length}
								</strong>{''}
							</span>
							<span className="table-index">
								Go to page : {' '}
								<input type="number" 
								className="ms-2"
								defaultValue={pageIndex + 1} 
								onChange = {e => { 
									const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0 
									gotoPage(pageNumber)
								} } 
								/>
							</span>
							</div>
							<div className="text-center">	
							<div className="filter-pagination  mt-3">
								<button className=" previous-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>
								
								<button className="previous-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
								Previous
								</button>
								<button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
								Next
								</button>
								<button className=" next-button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</button>
							</div>
							</div>
						</div>
					</div>
				</div>
			</div>
      	</div >

      	{/* Add notification modal */}
		<Modal className="fade" show={addModalOpen} onHide={() => { clearFields(); setAddModalOpen(false) }} centered >
			<Modal.Header>
				<Modal.Title>Add Notification</Modal.Title>
				<Button
					variant=""
					className="btn-close"
					onClick={() => { clearFields(); setAddModalOpen(false) }}
				></Button>
			</Modal.Header>
			<Modal.Body>
				<form>
					<div className="row">
						<div className="mb-12 col-lg-12 col-md-12 mb-2">
							<label className="form-label">Service</label>
							<div>
								<Select
									options={serviceCategoryList}
									value={serviceCategoryId}
									placeholder='Select Service'
									noOptionsMessage={() => "Please contact admin"}
									className="custom-react-select"
									isSearchable={false}
									onChange={(e) => {
										setServiceCategoryId(e);
										// setErrors({
										// 	...errors,
										// 	sub_service: "",
										// });
									}}
								/>
								{/* {errors.sub_service && (
									<div className="text-danger fs-12">
										{errors.sub_service}
									</div>
								)} */}
							</div>
						</div>
						
						<div className="mb-12 col-lg-12 col-md-12 mb-2">
							<label className="form-label">Notification Title</label>
							<div>
								<input
									type="text"
									className="form-control"
									placeholder="Enter Notification Title"
									name="notification_title"
									value={formData.notification_title}
									onChange={handleChange}
								/>
								{errors.notification_title && (
									<div className="text-danger fs-12">
										{errors.notification_title}
									</div>
								)}
							</div>
						</div>

						<div className="mb-3 col-12">
							<label className="form-label">Description</label>
							<div>
								<textarea
									className="form-control"
									rows="4"
									placeholder="Enter Notification Description"
									name="notification_desc"
									value={formData.notification_desc}
									onChange={handleChange}
								/>
								{errors.notification_desc && (
									<div className="text-danger fs-12">
										{errors.notification_desc}
									</div>
								)}
							</div>
						</div>

						<div className="mb-12 col-lg-12 col-md-12  mb-2">
							<label className="form-label">Status</label>
							<Select
								options={Status}
								className="custom-react-select"
								name="status"
								value={formData.status}
								onChange={(e) => handleStatusChange(e, "status")}
							/>
						</div>

						<div className="mb-12 col-lg-12 col-md-12 mb-2">
							<label className="form-label">Image</label>
							<div>
								<input
									type="file"
									className="form-control"
									name="image"
									onChange={handleFileChange}
								/>
								{errors.image && (
									<div className="text-danger fs-12">
										{errors.image}
									</div>
								)}
							</div>
						</div>

						<div className="mb-3 col-12">
							<Form.Group>
								<Form.Label>Fire on</Form.Label>
								<div className="d-flex w-full justify-content-between">
									<Form.Check
										type="checkbox"
										label="Until Check In"
										value="check_in"
										id="checkin"
										checked={availableAt?.includes("check_in")}
										onChange={handleAvailableAtChange}
									/>
									<Form.Check
										type="checkbox"
										label="Until Check Out"
										value="check_out"
										id="checkout"
										checked={availableAt?.includes("check_out")}
										onChange={handleAvailableAtChange}
									/>
									<Form.Check
										type="checkbox"
										label="While Stay"
										value="while_stay"
										id="while_stay"
										checked={availableAt?.includes("while_stay")}
										onChange={handleAvailableAtChange}
									/>
								</div>
								{errors.available_at && (
									<div className="text-danger fs-12">
										{errors.available_at}
									</div>
								)}
							</Form.Group>
						</div>
					</div>
				</form>
			</Modal.Body>
			<Modal.Footer>
				<Button onClick={() => { clearFields(); setAddModalOpen(false) }} variant="danger">
					{" "}
					Close{" "}
				</Button>
				<Button onClick={(e) => { e.preventDefault(); handleAddServiceNotification() }} variant="success"> Add </Button>
			</Modal.Footer>
		</Modal >

		{/* Edit service modal */}
		<Modal className="fade" show={showEditModal} onHide={() => { clearFields(); setShowEditModal(false) }} centered >
			<Modal.Header>
				<Modal.Title>Edit Notification</Modal.Title>
				<Button
					variant=""
					className="btn-close"
					onClick={() => { clearFields(); setShowEditModal(false) }}
				></Button>
			</Modal.Header>
			<Modal.Body>
				<form>
					<div className="row">
						<div className="mb-12 col-lg-12 col-md-12 mb-2">
							<label className="form-label">Service</label>
							<div>
								<Select
									options={serviceCategoryList}
									value={serviceCategoryId}
									placeholder='Select Service'
									noOptionsMessage={() => "Please contact admin"}
									className="custom-react-select"
									isSearchable={false}
									onChange={(e) => {
										setServiceCategoryId(e);
										// setErrors({
										// 	...errors,
										// 	sub_service: "",
										// });
									}}
								/>
								{/* {errors.sub_service && (
									<div className="text-danger fs-12">
										{errors.sub_service}
									</div>
								)} */}
							</div>
						</div>

						<div className="mb-12 col-lg-12 col-md-12 mb-2">
							<label className="form-label">Notification Title</label>
							<div>
								<input
									type="text"
									className="form-control"
									placeholder="Enter Notification Title"
									name="notification_title"
									value={formData.notification_title}
									onChange={handleChange}
								/>
								{errors.notification_title && (
									<div className="text-danger fs-12">
										{errors.notification_title}
									</div>
								)}
							</div>
						</div>

						<div className="mb-3 col-12">
							<label className="form-label">Description</label>
							<div>
								<textarea
									className="form-control"
									rows="4"
									placeholder="Enter Notification Description"
									name="notification_desc"
									value={formData.notification_desc}
									onChange={handleChange}
								/>
								{errors.notification_desc && (
									<div className="text-danger fs-12">
										{errors.notification_desc}
									</div>
								)}
							</div>
						</div>

						<div className="mb-12 col-lg-12 col-md-12  mb-2">
							<label className="form-label">Status</label>
							<Select
								options={Status}
								className="custom-react-select"
								name="status"
								value={formData.status}
								onChange={(e) => handleStatusChange(e, "status")}
							/>
						</div>

						<div className="mb-12 col-lg-12 col-md-12 mb-2">
							<label className="form-label">Image</label>
							<div>
								<input
									type="file"
									className="form-control"
									name="image"
									onChange={handleFileChange}
								/>
								{errors.image && (
									<div className="text-danger fs-12">
										{errors.image}
									</div>
								)}
							</div>
						</div>

						<div className="mb-3 col-12">
							<Form.Group>
								<Form.Label>Fire on</Form.Label>
								<div className="d-flex w-full justify-content-between">
									<Form.Check
										type="checkbox"
										label="Until Check In"
										value="check_in"
										id="checkin"
										checked={availableAt?.includes("check_in")}
										onChange={handleAvailableAtChange}
									/>
									<Form.Check
										type="checkbox"
										label="Until Check Out"
										value="check_out"
										id="checkout"
										checked={availableAt?.includes("check_out")}
										onChange={handleAvailableAtChange}
									/>
									<Form.Check
										type="checkbox"
										label="While Stay"
										value="while_stay"
										id="while_stay"
										checked={availableAt?.includes("while_stay")}
										onChange={handleAvailableAtChange}
									/>
								</div>
								{errors.available_at && (
									<div className="text-danger fs-12">
										{errors.available_at}
									</div>
								)}
							</Form.Group>
						</div>
					</div>
				</form>
			</Modal.Body>
			<Modal.Footer>
				<Button onClick={() => { clearFields(); setShowEditModal(false) }} variant="danger">
					{" "}
					Close{" "}
				</Button>
				<Button onClick={(e) => { e.preventDefault(); handleEditSubService() }} variant="success"> Save </Button>
			</Modal.Footer>
		</Modal >
    </>
  );
}

export default ServiceNotification;
