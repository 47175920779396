import React, { useEffect, useState, useRef, useMemo } from "react";
import {
    useTable,
    useGlobalFilter,
    useFilters,
    usePagination,
} from "react-table";

import { ColumnFilter } from "../FilteringTable/ColumnFilter";
import PmService from "../../../services/PmService";
import { Button, Dropdown, Form, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";

const Inventory = ({ propertyId }) => {
    const COLUMNS = [
		{
		  Header: 'Item Name',
		  Footer: 'Item Name',
		  accessor: 'item_name',
		  Filter: ColumnFilter,
          style: { width: "60%" }
		},
		{
		  Header: 'Item Count',
		  Footer: 'Item Count',
		  accessor: 'item_count',
		  Filter: ColumnFilter,
		},
		{
		  Header: 'Delete',
		  Footer: 'Delete',
		  Cell: ({ row }) => {
			const item = row.original;
			return (
				<Button
					onClick={() => {
                        deleteItem(item.id);
					}}
                    style={{
                        backgroundColor: 'red',
                        borderColor: 'red',
                    }}
					className="btn btn-primary shadow btn-xs sharp me-1"
				>
					<i className="fa fa-trash"></i>
				</Button>
			);
		  },
		}
	];

    const [item_name, setItem_name] = useState("");
    const [item_count, setItem_count] = useState(1);
    const [inventoryList, setInventoryList] = useState([]);

    const handleInventorySubmit = (e) => {
        e.preventDefault();

        const passData = {
            property_id: propertyId,
            item_name: item_name,
            item_count: item_count,
        };

        PmService.AddPropertyInventory(passData)
            .then(res => {
                if (res.success) {
                    fetchInventoryList();
                }
            })
            .catch(error => {
                console.log(error);
            }).finally(() => {
                setItem_name("");
            });
    };

    const fetchInventoryList = () => {
        PmService.GetPropertyInventory(propertyId)
            .then(res => {
                setInventoryList(res?.data);
            })
            .catch(error => {
                console.log(error);
            });
    };

    const deleteItem = (id) => {
        PmService.DeletePropertyInventory(id)
            .then(res => {
                if (res.success) {
                    fetchInventoryList();
                }
            })
            .catch(error => {
                console.log(error);
            });
    };

    useEffect(() => {
        fetchInventoryList();
    }, []);

    const columns = useMemo(() => COLUMNS, []);
	const data = useMemo(() => inventoryList, [inventoryList]);
	const tableInstance = useTable({
		columns,
		data,
		initialState: {
		pageIndex: 0
		}
	}, useFilters, useGlobalFilter, usePagination);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		state,
		page,
		gotoPage,
		pageCount,
		pageOptions,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		setGlobalFilter,
	} = tableInstance;


	const {
		globalFilter,
		pageIndex
	} = state;

    return <>
        <div className="mb-12 col-lg-10 col-md-12 mb-2">
            <label className="form-label">Inventory List</label>
            <div style={{ display: "flex", alignItems: "center" }}>
                <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Item Name"
                    name="service_title"
                    value={item_name}
                    onChange={(e) => setItem_name(e.target.value)}
                />
                <input
                    type="number"
                    className="form-control"
                    placeholder="Enter Item Count"
                    name="service_title"
                    style={{ marginLeft: "20px", width: "200px" }}
                    min={1}
                    value={item_count}
                    onChange={(e) => setItem_count(e.target.value)}
                />
                <Button
                    onClick={handleInventorySubmit}
                    variant="success"
                    style={{ marginLeft: "20px" }}
                >
                    Add
                </Button>
            </div>
        </div>

        {
            inventoryList.length > 0 && (
                <div className="table-responsive table-hover fs-14 order-list-table">
                    <table
                        {...getTableProps()}
                        className="table filtering-table table-responsive-lg"
                    >
                        <thead>
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th {...column.getHeaderProps()}  style={column.style}>
                                            {column.render("Header")}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()} className="">
                            {page.map((row) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell) => {
                                            return (
                                                <td {...cell.getCellProps()} style={cell.column.style}>
                                                    {" "}
                                                    {cell.render("Cell")}{" "}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    <div className="d-flex justify-content-between">
                        <span>
                            Page{" "}
                            <strong>
                                {pageIndex + 1} of {pageOptions.length}
                            </strong>
                            {""}
                        </span>
                        <span className="table-index">
                            Go to page :{" "}
                            <input
                                type="number"
                                className="ms-2"
                                defaultValue={pageIndex + 1}
                                onChange={(e) => {
                                    const pageNumber = e.target.value
                                        ? Number(e.target.value) - 1
                                        : 0;
                                    gotoPage(pageNumber);
                                }}
                            />
                        </span>
                    </div>
                    <div className="text-center">
                        <div className="filter-pagination  mt-3">
                            <button
                                className=" previous-button"
                                onClick={() => gotoPage(0)}
                                disabled={!canPreviousPage}
                            >
                                {"<<"}
                            </button>

                            <button
                                className="previous-button"
                                onClick={() => previousPage()}
                                disabled={!canPreviousPage}
                            >
                                Previous
                            </button>
                            <button
                                className="next-button"
                                onClick={() => nextPage()}
                                disabled={!canNextPage}
                            >
                                Next
                            </button>
                            <button
                                className=" next-button"
                                onClick={() => gotoPage(pageCount - 1)}
                                disabled={!canNextPage}
                            >
                                {">>"}
                            </button>
                        </div>
                    </div>
                </div>
            )
        }
    </>;
};

export default Inventory;
