import React, { useEffect, useState, useRef, useMemo } from "react";
import {
	useTable,
	useGlobalFilter,
	useFilters,
	usePagination,
} from "react-table";
import { Link } from "react-router-dom";
import profile from "../../../images/avatar/1.jpg";
import { Button, Dropdown, Form, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import PmService from "../../../services/PmService";
import moment from "moment";
import Loader from "../Loader";
import ModalLoader from "../Loader/ModalLoader";
import Swal from "sweetalert2";
import { ColumnFilter } from "../FilteringTable/ColumnFilter";
import "../FilteringTable/filtering.css";
import { format } from "date-fns";
import { Cell } from "recharts";
import Select from "react-select";
import emojiRegex from 'emoji-regex';

function PreviousDamage() {
  const COLUMNS = [
    {
        Header: 'Booking ID',
        Footer: 'Booking ID',
        accessor: 'booking_id',
        Filter: ColumnFilter,
    },
    {
        Header: 'Guest Name',
        Footer: 'Guest Name',
        accessor: 'guest_name',
        Filter: ColumnFilter,
    },
    {
        Header: 'Property',
        Footer: 'Property',
        accessor: 'property_name',
        Filter: ColumnFilter,
    },
    {
        Header: 'Email',
        Footer: 'Email',
        accessor: 'email',
        Filter: ColumnFilter,
    },
    {
        Header: 'Phone',
        Footer: 'Phone',
        accessor: 'phone',
        Filter: ColumnFilter,
    },
    {
        Header: 'Comments',
        Footer: 'Comments',
        accessor: 'comments',
        Filter: ColumnFilter,
    },
    {
        Header: 'Images',
        Footer: 'Images',
        accessor: 'images',
        Filter: ColumnFilter,
		Cell: ({ row }) => {
			setImagesList(row?.original?.images);

            return (
                <div>
                    <Button
						className="btn btn-primary shadow btn-xs sharp me-1"
						onClick={() => handleImagesModal(true)}
					>
						<i className="fa fa-eye"></i>
					</Button>
                </div>
            );
        },
    },
  ]

  const [loader, setLoader] = useState(false);
  const [imagesModal, setImagesModal] = useState(false);
  const [imagesList, setImagesList] = useState([]);
  const [singleImageModal, setSingleImageModal] = useState(false);
  const [imageLink, setImageLink] = useState("");
  const [previousDamageList, setPreviousDamageList] = useState([]);

  const handleImagesModal = (val) => {
	setImagesModal(val);
  };

  const fetchPreviousDamage = () => {
    PmService.GetPropertyPreviousDamage()
      .then((resp) => {
        setPreviousDamageList(resp.data);
      })
      .catch((err) => {
        console.log("err = ", err);
      })
      .finally(function () {
        setLoader(false);
      })
  }

  useEffect(() => {
    setLoader(true);
    fetchPreviousDamage()
  }, []);

	const columns = useMemo(() => COLUMNS, []);
	const data = useMemo(() => previousDamageList, [previousDamageList]);
	const tableInstance = useTable({
		columns,
		data,
		initialState: {
			pageIndex: 0
		}
	}, useFilters, useGlobalFilter, usePagination);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		state,
		page,
		gotoPage,
		pageCount,
		pageOptions,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		setGlobalFilter,
	} = tableInstance;


	const {
		globalFilter,
		pageIndex
	} = state;

	return loader ? (
		<Loader />
	) : (
    <>
		<div className="row">
			<div className="col-xl-12">
				<div className="card">
					<div className="card-body">
						{/* <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
							<span style={{marginRight: "10px"}}>Checkin :</span>
							<select
								name="checkin"
								className="form-select form-control-lg rounded"
								style={{
									cursor: "pointer",
									width: "fit-content",
									fontSize: "14px",
									backgroundColor: "var(--primary)",
									color: "white",
									marginRight: "10px",
								}}
								value={selectedValue}
								onChange={(e) => {
									setSelectedValue(e.target.value);
									handleFilterSelect(e);
								}}
							>
								<option value="">All</option>
								{
									range.map((item, index) => (
									<option key={index} value={item}>{item}</option>
									))
								}
							</select>

							<span style={{marginRight: "10px"}}>Checkout :</span>
							<select
								name="checkout"
								className="form-select form-control-lg rounded"
								style={{
									cursor: "pointer",
									width: "fit-content",
									fontSize: "14px",
									backgroundColor: "var(--primary)",
									color: "white",
									marginRight: "10px",
								}}
								onChange={handleFilterSelect}
							>
								<option value="">All</option>
								{
									range.map((item, index) => (
									<option key={index} value={item}>{item}</option>
									))
								}
							</select>
						</div> */}

						<div className="table-responsive table-hover fs-14 order-list-table">
							<table {...getTableProps()} className="table filtering-table table-responsive-lg">
							<thead>
								{headerGroups.map(headerGroup => (
								<tr {...headerGroup.getHeaderGroupProps()}>
									{headerGroup.headers.map(column => (
									<th {...column.getHeaderProps()}>
										{column.render('Header')}
										{column.canFilter ? column.render('Filter') : null}
									</th>
									))}
								</tr>
								))}
							</thead> 
							<tbody {...getTableBodyProps()} className="" >
							
								{page.map((row) => {
								prepareRow(row)
								return(
									<tr {...row.getRowProps()}>
									{row.cells.map((cell) => {
										return <td {...cell.getCellProps()}> {cell.render('Cell')} </td>
									})}
									</tr>
								)
								})}
							</tbody>
							</table>
							<div className="d-flex justify-content-between">
							<span>
								Page{' '}
								<strong>
								{pageIndex + 1} of {pageOptions.length}
								</strong>{''}
							</span>
							<span className="table-index">
								Go to page : {' '}
								<input type="number" 
								className="ms-2"
								defaultValue={pageIndex + 1} 
								onChange = {e => { 
									const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0 
									gotoPage(pageNumber)
								} } 
								/>
							</span>
							</div>
							<div className="text-center">	
							<div className="filter-pagination  mt-3">
								<button className=" previous-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>
								
								<button className="previous-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
								Previous
								</button>
								<button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
								Next
								</button>
								<button className=" next-button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</button>
							</div>
							</div>
						</div>
					</div>
				</div>
			</div>
        </div>

		{/* Multi Images Modal */}
	    <Modal className="fade" show={imagesModal} onHide={() => setImagesModal(false)} size="lg" centered>
			<Modal.Header>
				<Modal.Title>Images</Modal.Title>
				<Button
					variant=""
					className="btn-close"
					onClick={() => setImagesModal(false)}
				></Button>
			</Modal.Header>
			<Modal.Body>
				{imagesList.length > 0 ? (
					<div className="container">
						<div className="row">
								{imagesList.map((image, index) => (
									<div key={index} className="col-md-4 mb-3" style={{ cursor: "pointer" }}>
										<img
											src={image}
											alt={`Image ${index + 1}`}
											className="img-fluid rounded"
											onClick={() => {
												setSingleImageModal(true);
												setImageLink(image);
											}}
										/>
									</div>
								))}
						</div>
					</div>
				) : (
					<>No images available</>
				)}
			</Modal.Body>
        </Modal>

		{/* Single Image Modal */}
	    <Modal className="fade" show={singleImageModal} onHide={() => setSingleImageModal(false)} size="lg" centered>
			<Modal.Header>
				<Button
					variant=""
					className="btn-close"
					onClick={() => setSingleImageModal(false)}
				></Button>
			</Modal.Header>
			<Modal.Body>
				<div className="container">
					<div className="row">
						<div className="col">
							<img
								src={imageLink}
								alt="damage image"
								className="img-fluid rounded"
							/>
						</div>
					</div>
				</div>
			</Modal.Body>
        </Modal>
    </>
  );
}

export default PreviousDamage;
